function loadScript() {
	$(".quiz__qna-list-item").on("click", function () {
		var parent = $(this);
		if (parent.hasClass("active")) {
			parent.removeClass("active");
		} else {
			let mainBlock = $(this).parent();
			let childrenBlock = mainBlock.children();

			for (let i = 0; i < childrenBlock.length; i++) {
				childrenBlock[i].classList.remove("active");
			}
			parent.addClass("active");
		}
	});
}
loadScript();
